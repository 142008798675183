import React from "react"
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import arrayMove from "../../services/arrayMove"
import DateRangePicker from "../shared/DateRangePicker"

import PositionMembership from "./PositionMembership"
import PositionStrategySelect from "./PositionStrategySelect"

import AddPositionMembershipsModal from "./AddPositionMembershipsModal"

import {
  DelayBetweenMessagesField,
  LineItemsField,
  InvitationMessageField,
  AcceptedMessageField,
  DeclinedMessageField,
  AlreadyTakenMessageField,
  UseDefaultMessageButton,
} from "./ProjectPositionFields"

const messageFields = ["invitationMessage", "acceptedMessage", "declinedMessage", "alreadyTakenMessage"]

const SortableMembership = SortableElement(props => <PositionMembership {...props} />)

const SortableMemberships = SortableContainer(({ project, position, memberships, onChange, onRemove }) => {
  return (
    <table className="table table-border-row table-vertical-squeeze position-memberships mb-0">
      <tbody>
        {memberships.map((membership, i) => (
          <SortableMembership
            key={i}
            index={i}
            project={project}
            membership={membership}
            position={position}
            onChange={onChange}
            onRemove={() => onRemove(membership)}
          />
        ))}
      </tbody>
    </table>
  )
})

export default function({ tab, project, projectPosition, position, availableDepartments, availablePeople, defaultMessages, onChange, onRemove }) {
  const { positionId, roleName, assignmentStrategy, roundRobinHourDelay, memberships } = projectPosition
  const [isAddingMemberships, setAddingMemberships] = React.useState(false)
  const messages = {}
  messageFields.forEach(f => messages[f] = projectPosition[f] || defaultMessages[f])

  const selectedPeopleIds = memberships.map(m => m.person.id)
  const availablePeopleToAdd = availablePeople.filter(p => {
    const i = selectedPeopleIds.indexOf(p.id)

    return i === -1 || memberships[i].remove
  })

  function handleRemove(e) {
    e.preventDefault()
    // TODO: prettier confirmation dialog
    if (confirm("Are you sure?")) {
      onRemove()
    }
  }

  function handleOpenAddMemberships(e) {
    e.preventDefault()
    setAddingMemberships(true)
  }

  function handleCloseAddMemberships() {
    setAddingMemberships(false)
  }

  function handleAddMemberships(people) {
    const changedMemberships = [...memberships]

    people.forEach(person => {
      const i = selectedPeopleIds.indexOf(person.id)
      const position = person.positions.find(p => p.id === positionId) || {}
      let membership = null

      if (i === -1) {
        membership = {
          remove: false,
          person,
        }
        changedMemberships.push(membership)
      } else {
        membership = changedMemberships[i]
        changedMemberships.splice(i, 1)
        changedMemberships.push(membership)

        membership.remove = false
      }

      if (!membership.rateAmount) { membership.rateAmount = position.rateAmount }
      if (!membership.rateCurrency) { membership.rateCurrency = position.rateCurrency }
      if (!membership.rateDuration) { membership.rateDuration = position.rateDuration }
    })

    onChange({ memberships: changedMemberships })
    setAddingMemberships(false)
  }

  function handleChangeMembership(membershipsToChange) {
    const mems = [...memberships]
    membershipsToChange.forEach(change => {
      const i = mems.findIndex(m => m.person.id === change.person.id)
      if (i !== -1) {
        mems[i] = { ...mems[i], ...change }
      }
    })

    onChange({ memberships: mems })
  }

  function handleRemoveMemberships(membershipsToRemove) {
    const peopleIds = membershipsToRemove.map(m => m.person.id)
    const changedMemberships = []

    memberships.forEach(m => {
      if (peopleIds.indexOf(m.person.id) === -1) {
        changedMemberships.push(m)
      } else if (m.id) {
        changedMemberships.push({ ...m, remove: true })
      }
    })

    onChange({ memberships: changedMemberships })
  }

  function handleAssignmentStrategyChange(assignmentStrategy) {
    onChange({
      assignmentStrategy,
    })
  }

  function handleReorderMemberships(e) {
    const reorderedMemberships = [...memberships]
    arrayMove(reorderedMemberships, e.oldIndex, e.newIndex)
    onChange({ memberships: reorderedMemberships })
  }

  return (
    <div className="position">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 mb-2">
          {/** TODO: remove or disable the already selected positions from the list of options */}
          <select value={projectPosition.positionId} className="form-control" onChange={e => onChange({ positionId: e.target.value })}>
            {!projectPosition.positionId && <option>Select Position</option>}
            {availableDepartments.map(department => (
              <optgroup key={department.id} label={department.name}>
                {department.positions.map(p => (
                  <option
                    key={p.id}
                    value={p.id}
                    children={p.name}
                  />
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        {projectPosition.positionId ? (
          <>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 mb-2">
              <input
                type="text"
                value={roleName}
                placeholder="Role"
                onChange={s => onChange({ roleName: s.target.value })}
                className="form-control"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 mb-2">
              <PositionStrategySelect
                value={assignmentStrategy}
                onChange={handleAssignmentStrategyChange}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 mb-2">
              <select value={projectPosition.openingsCount} className="form-control" onChange={e => onChange({ openingsCount: parseInt(e.target.value) })}>
                {[1,2,3,4,5,6].map(choice => (
                  <option
                    key={choice}
                    value={choice}
                    children={`${choice} ${choice === 1 ? "opening" : "openings"}`}
                  />
                ))}
              </select>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-2">
              <DateRangePicker
                minDate={project.startDate}
                maxDate={project.endDate}
                startDate={projectPosition.startDate}
                endDate={projectPosition.endDate}
                startHalfDay={projectPosition.startHalfDay}
                endHalfDay={projectPosition.endHalfDay}
                onChange={onChange}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-1 col-xl-1 text-right">
              <button className="btn btn-outline-danger" onClick={handleRemove}>
                <i className="fa fa-times"/>
              </button>
            </div>
          </>
        ) : (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 text-right">
            <button className="btn btn-outline-danger" onClick={handleRemove}>
              <i className="fa fa-times"/>
            </button>
          </div>
        )}
      </div>

      <div className="row">
        {tab === "people" ? (
          <>
            {assignmentStrategy === "round_robin_assignment" && (
              <DelayBetweenMessagesField
                roundRobinHourDelay={roundRobinHourDelay}
                onChange={onChange}
              />
            )}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <SortableMemberships
                axis="y"
                useDragHandle
                position={position}
                project={project}
                memberships={projectPosition.memberships}
                onChange={props => handleChangeMembership([props])}
                onRemove={membership => handleRemoveMemberships([membership])}
                onSortEnd={handleReorderMemberships}
              />
              <button className="btn btn-outline-dark btn-add-membership ml-0" onClick={handleOpenAddMemberships}>
                <i className="fa fa-plus-circle pr-4" />
                Add Person
              </button>
            </div>
          </>
        ) : tab === "additional_pay" ? (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
            <div className="row">
              <LineItemsField
                lineItems={projectPosition.lineItems}
                onChange={onChange}
              />
            </div>
          </div>
        ) : tab === "messages" ? (
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
            <div className="row">
              <InvitationMessageField
                value={messages.invitationMessage}
                onChange={v => onChange({ invitationMessage: v })}
              />
              <AcceptedMessageField
                value={messages.acceptedMessage}
                onChange={v => onChange({ acceptedMessage: v })}
              />
              <DeclinedMessageField
                value={messages.declinedMessage}
                onChange={v => onChange({ declinedMessage: v })}
              />
              <AlreadyTakenMessageField
                value={messages.alreadyTakenMessage}
                onChange={v => onChange({ alreadyTakenMessage: v })}
              />
              <UseDefaultMessageButton onClick={() => onChange(defaultMessages)} />
            </div>
          </div>
        ) : null}
      </div>

      <AddPositionMembershipsModal
        isOpen={isAddingMemberships}
        positionId={positionId}
        positionName={position.name}
        roleName={projectPosition.roleName}
        availablePeople={availablePeopleToAdd}
        onAdd={handleAddMemberships}
        onCancel={handleCloseAddMemberships}
      />
    </div>
  )
}
