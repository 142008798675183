import React from "react"

const choices = [{
  id: "first_come_first_serve_assignment",
  name: "Bulk Offers",
}, {
  id: "manually_offer",
  name: "Manually Deliver Offers",
}, {
  id: "round_robin_assignment",
  name: "Round Robin Offers",
}]

export default function({ value, onChange }) {
  return (
    <select value={value} className="form-control" onChange={e => onChange(e.target.value)}>
      {choices.map(choice => (
        <option
          key={choice.id}
          value={choice.id}
          children={choice.name}
        />
      ))}
    </select>
  )
}
