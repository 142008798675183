import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectFolder", "newFolder", "newFolderTextField" ]
  toggleNewFolder(e) {
    e.preventDefault()

    this.selectFolderTarget.classList.toggle("d-none");
    this.newFolderTarget.classList.toggle("d-none");

    if (!this.newFolderTarget.classList.contains("d-none")) {
      this.newFolderTextFieldTarget.focus();
    }
  }
}
