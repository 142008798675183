import { Controller } from 'stimulus';

function getIndex(elem) {
  const index = parseInt(elem.dataset.index);
  return (index || index === 0) ? index : null;
}

function getIndexElements(element, index) {
  const e = element.querySelectorAll(`*[data-index="${index}"]`);
  return Array.from(e);
}

function getFirstIndexElement(element, index) {
  return element.querySelector(`*[data-index="${index}"]`);
}

function toggleButton(button, isOn) {
  if (isOn) {
    button.removeAttribute("disabled");
    button.classList.remove("disabled");
    button.removeAttribute("aria-disabled")
  } else {
    button.setAttribute("disabled", "true");
    button.classList.add("disabled");
    button.setAttribute("aria-disabled", "true")
  }
}

function closeMenu(elem) {
  const menu = elem.closest(".dropdown-menu");
  menu && menu.classList.remove("show");
}

function getParentAndIndex(elem) {
  const parent = elem.closest("*[data-index]");
  const index = parent && getIndex(parent);

  return [ parent, index ];
}

export default class extends Controller {
  static targets = [
    "listContainer", "positionLabel",
  ];

  connect() {
    this.refresh();
  }

  appendNew(e) {
    e.preventDefault();

    const html = this.generateItemHTML()
    this.listContainerTarget.insertAdjacentHTML("beforeend", html);

    this.refresh()
  }

  insertUp(e) {
    e.preventDefault();
    closeMenu(e.target)

    const html = this.generateItemHTML();
    const [parent, index] = getParentAndIndex(e.target);

    const firstParent = getFirstIndexElement(this.element, index);
    firstParent.insertAdjacentHTML("beforebegin", html);

    this.refreshPositions();
  }

  insertDown(e) {
    e.preventDefault();
    closeMenu(e.target);

    const html = this.generateItemHTML();
    const [parent, index] = getParentAndIndex(e.target);

    const allParents = getIndexElements(this.element, index);
    const lastParent = allParents[allParents.length - 1];
    lastParent.insertAdjacentHTML("afterend", html);

    this.refreshPositions();
  }

  moveUp(e) {
    e.preventDefault();
    closeMenu(e.target);

    const [parent, index] = getParentAndIndex(e.target);
    const allParents = getIndexElements(this.element, index);

    const prevParent = allParents[0].previousElementSibling;
    const prevIndex = prevParent && getIndex(prevParent);
    const prevFirstParent = getFirstIndexElement(this.element, prevIndex);

    allParents.forEach(elem => {
      const h = elem.outerHTML
      prevFirstParent.insertAdjacentHTML("beforebegin", h);
      elem.remove()
    })

    this.refresh()
  }

  moveDown(e) {
    e.preventDefault();
    closeMenu(e.target)

    const [parent, index] = getParentAndIndex(e.target);
    const allParents = getIndexElements(this.element, index);
    const lastParent = allParents[allParents.length - 1];

    const nextParent = lastParent.nextElementSibling;
    const nextIndex = nextParent && getIndex(nextParent);
    const nextParents = getIndexElements(this.element, nextIndex);
    const nextLastParent = nextParents[nextParents.length - 1];

    allParents.reverse().forEach(elem => {
      const h = elem.outerHTML
      nextLastParent.insertAdjacentHTML("afterend", h);
      elem.remove()
    })

    this.refresh()
  }

  removeItem(e) {
    e.preventDefault();

    const confirmMessage = e.target.dataset.confirm2;

    if (confirmMessage && confirm(confirmMessage) || !confirmMessage) {
      this.actuallyRemoveItem(e)
    }
  }

  // PRIVATE METHODS

  actuallyRemoveItem(e) {
    const [parent, index] = getParentAndIndex(e.target);
    if (index || index === 0) {
      getIndexElements(this.element, index).forEach(elem => {
        elem.classList.add("d-none");

        const d = elem.querySelector("input[data-destroy]")
        if (d) {
          d.value = "1";
        }
      })
    }
  }

  refresh() {
    this.refreshPositions()
    this.refreshActions()
  }

  refreshPositions() {
    Array.from(this.element.querySelectorAll("input[data-position]")).forEach((elem, i) => {
      // NOT zero indexed.
      const position = i + 1;

      elem.value = position;

      const label = elem.parentElement.querySelector("span[data-position]")
      if (label) {
        label.innerHTML = position.toString();
      }
    })
  }

  refreshActions() {
    const elems = Array.from(this.element.querySelectorAll("*[data-index]"));
    const firstIndex = elems[0] ? getIndex(elems[0]) : null;
    const lastIndex = elems[elems.length - 1] ? getIndex(elems[elems.length - 1]) : null;

    Array.from(this.element.querySelectorAll("*[data-index]")).forEach(elem => {
      const i = getIndex(elem)

      Array.from(elem.querySelectorAll("*[data-disabled-when-first")).forEach(elem2 => {
        toggleButton(elem2, i !== firstIndex)
      });
      Array.from(elem.querySelectorAll("*[data-disabled-when-last")).forEach(elem2 => {
        toggleButton(elem2, i !== lastIndex)
      });
    });
  }

  generateItemHTML() {
    const itemFields = this.element.dataset.itemFields;
    const items = this.element.querySelectorAll("*[data-index]");
    let index = 0;

    items.forEach((elem, num) => {
      const i = getIndex(elem);
      if ((i || i === 0) && i > index) {
        index = i;
      }
    })
    index += 1;

    return new String(itemFields).replaceAll("ITEM_INDEX", index);
  }
}
