import { Controller } from "stimulus"
import ActionCable from "../channels/consumer"

import { htmlToElement } from "../services/dom"
import getParams from "../services/getParams"

export default class extends Controller {
  static targets = ["filter", "list"]

  connect() {
    this.subscription = ActionCable.subscriptions.create({
      channel: "MessageRecipientsChannel",
      ...getParams(this.element.dataset, {
        projectId: "project_id",
        personId: "person_id",
      }),
    }, {
      connected: () => {
        console.log("connected") // eslint-disable-line no-console
      },
      received: data => {
        if (data.action === "new_message") {
          console.log("received new message") // eslint-disable-line no-console
          this.updateRecipientsForNewMessage(data)
        }
      },
      disconnected: () => {
        console.log("disconnected") // eslint-disable-line no-console
      },
    })
  }

  disconnect() {
    if (this.subscription) {
      ActionCable.subscriptions.remove(this.subscription)
    }
  }

  updateRecipientsForNewMessage(data) {
    const existing = document.getElementById(data.recipient_id)
    const isActive = existing && existing.classList.contains("dc-active")

    if (existing) {
      existing.remove()
    }

    const newRecipient = htmlToElement(data.recipient)
    if (isActive) {
      newRecipient.classList.add("dc-active")
    }
    this.listTarget.prepend(newRecipient)
  }

  filter(e) {
    if (e.key === "Escape") {
      this.filterTarget.value = ""
    }

    const query = this.filterTarget.value.toLowerCase()

    Array.from(this.listTarget.children).forEach(child => {
      const search = Array.from(child.querySelectorAll("*[data-search-text]")).map(s => s.innerText.toLowerCase()).join(" ")

      const hidden = search.indexOf(query) === -1
      child.classList.toggle("d-none", hidden)
    })
  }
}
