import { Controller } from "stimulus"

export default class extends Controller {
  toggleSidebar() {
    const wrapper = document.getElementById("dc-wrapper")
    if (wrapper) {
      wrapper.classList.toggle("dc-closemenu")
    }
  }

  toggleUserMenu(e) {
    e.preventDefault();
    this.element.classList.toggle("open")
  }

  toggleMobile(e) {
    e.preventDefault();
    this.element.querySelectorAll(".mobile-toggle").forEach(div => {
      div.classList.toggle("d-block")
    })
  }
}
