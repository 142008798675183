import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "button", "menu"]

  connect() {
    const closeMenu = () => {
      this.menuTarget.classList.remove("show")
      document.removeEventListener("click", clickAwayListener) // eslint-disable-line no-use-before-define
      document.removeEventListener("keyup", keyListener) // eslint-disable-line no-use-before-define
    }

    const clickAwayListener = e => {
      if (!this.menuTarget.contains(e.target) && !this.buttonTarget.contains(e.target)) {
        closeMenu()
      }
    }

    const keyListener = e => {
      if (e.key === "Escape") {
        closeMenu()
      }
    }

    this.buttonTarget.addEventListener("click", () => {
      this.menuTarget.classList.toggle("show")
      document.addEventListener("click", clickAwayListener)
      document.addEventListener("keyup", keyListener)
    })

    this.menuTarget.querySelectorAll("a").forEach(a => {
      a.addEventListener("click", e => {
        e.preventDefault()
        this.inputTarget.value = a.dataset.value
        this.buttonTarget.innerHTML = `${a.innerHTML} `
        closeMenu()
      })
    })
  }
}
