import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.value = this.element.value
  }

  update() {
    const elem = this.element
    const { path } = elem.dataset
    const { name } = elem.dataset
    const method = elem.dataset.method || "POST"
    const headers = {
      "Content-Type": elem.dataset.contentType || "application/json",
      Accept: elem.dataset.contentType || "application/json",
    }

    const body = {}
    const namePieces = name.replace("]", "").split("[")
    if (namePieces.length === 2) {
      body[namePieces[0]] = {}
      body[namePieces[0]][namePieces[1]] = elem.value
    }

    const param = document.querySelector("meta[name=csrf-param]").content
    body[param] = document.querySelector("meta[name=csrf-token]").content

    fetch(path, { method, headers, body: JSON.stringify(body) }).then(r => {
      if (r.status === 200) {
        this.value = elem.value
      } else {
        elem.value = this.value
      }
    })
  }
}
