// ensure timezones don't make this off by one day
export default function parseDate(string) {
  if (!string) { return }
  if (string.getDay) { return string }

  const a = string.split("-")
  const date = new Date(parseInt(a[0]), parseInt(a[1]) - 1, parseInt(a[2]))

  if (isNaN(date)) {
    throw `Invalid date: ${string}`;
  }

  return date
}
