import React from "react"
import PrettySelect from "./PrettySelect"

export default function({ value, onChange }) {
  const choices = [
    ["per_hour", "per hour"],
    ["per_day", "per day"],
    ["per_project", "per project"],
  ]
  return (
    <PrettySelect value={value} choices={choices} onChange={onChange} />
  )
}
