import React from "react"
import ReactDOM from "react-dom"
import ReactModal from "react-modal"
import { Controller } from "stimulus"

import DateRangePicker from "../components/shared/DateRangePicker"

export default class extends Controller {
  static targets = [
    "placeholder", "startDate", "endDate", "startHalfDay", "endHalfDay", "daysCount",
  ]

  connect() {
    ReactModal.setAppElement("body")
    ReactDOM.render(
      <DateRangePicker
        minDate={this.element.dataset.projectStartDate}
        maxDate={this.element.dataset.projectEndDate}
        startDate={this.startDateTarget.value}
        endDate={this.endDateTarget.value}
        startHalfDay={this.startHalfDayTarget.value}
        endHalfDay={this.endHalfDayTarget.value}
        onChange={p => this.handleChange(p)}
      />,
      this.placeholderTarget,
    )
  }

  handleChange(props) {
    console.log(props)
    this.startDateTarget.value = props.startDate
    this.endDateTarget.value = props.endDate
    this.startHalfDayTarget.value = props.startHalfDay ? "1" : ""
    this.endHalfDayTarget.value = props.endHalfDay ? "1" : ""

    this.connect()
  }
}
