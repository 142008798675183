import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["defaultMessages", "defaultsExplanation"]

  toggleDefaultMessages(e) {
    e.preventDefault()

    this.defaultsExplanationTarget.classList.toggle("d-none")
    this.defaultMessagesTargets.forEach(t => {
      t.classList.toggle("d-none")
    })
  }
}
