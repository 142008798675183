// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "whatwg-fetch" // polyfill fetch for ajax calls
import "../services/notify"
import "../services/notificationsSubscription"
import Flatpickr from "stimulus-flatpickr"

// Start Stylesheets
import "../../../vendor/doclisteo/css/fontawesome/fontawesome-all.css"
import "../../../vendor/doclisteo/css/font-awesome.min.css"
import "../../../vendor/doclisteo/css/bootstrap.min.css"
import "../../../vendor/doclisteo/css/normalize.css"
import "../../../vendor/doclisteo/css/scrollbar.css"
import "../../../vendor/doclisteo/css/themify-icons.css"
import "../../../vendor/doclisteo/css/dashboard.css"
import "../../../vendor/doclisteo/css/transitions.css"
import "../../../vendor/doclisteo/css/dbresponsive.css"

import "flatpickr/dist/flatpickr.css"

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "../stylesheets/theme-overrides.scss"
import "../stylesheets/shared.scss"
import "../stylesheets/devise.scss"
import "../stylesheets/alerts.scss"
import "../stylesheets/notifications.scss"
import "../stylesheets/activities.scss"
import "../stylesheets/call-sheets.scss"
import "../stylesheets/departments.scss"
import "../stylesheets/messages.scss"
import "../stylesheets/people.scss"
import "../stylesheets/projects.scss"
import "../stylesheets/project-team-form.scss"
import "../stylesheets/user-profile.scss"
// End Stylesheets

console.log("initializing BEV application.js") // eslint-disable-line no-console
Rails.start()
Turbolinks.start()
ActiveStorage.start()

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Manually register Flatpickr as a stimulus controller
application.register("flatpickr", Flatpickr)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
