import React from "react"

export default function({ value, choices, onChange }) {
  const [isOpen, setOpen] = React.useState(false)
  const current = choices.find(c => c[0] === value)

  function handleClick(e, value) {
    e.preventDefault()
    current[0] !== value && onChange(value)
    setOpen(false)
  }

  return (
    <>
      <button
        className="btn btn-outline-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        data-aria-haspopup="true"
        data-aria-expanded="false"
        children={current && current[1]}
        onClick={() => setOpen(!isOpen)}
      />
      <div className={`dropdown-menu${isOpen ? " show" : ""}`}>
        {choices.map((c, i) => (
          <a key={i} className="dropdown-item" href="#" onClick={e => handleClick(e, c[0])}>{c[1]}</a>
        ))}
      </div>
    </>
  )
}
