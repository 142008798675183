import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "menu"]

  connect() {
    const button = this.element.querySelector(".dropdown-toggle")
    const menu = this.element.querySelector(".dropdown-menu")

    const closeMenu = () => {
      menu.classList.remove("show")
      document.removeEventListener("click", clickAwayListener) // eslint-disable-line no-use-before-define
      document.removeEventListener("keyup", keyListener) // eslint-disable-line no-use-before-define
    }

    const clickAwayListener = e => {
      if (!menu.contains(e.target) && !button.contains(e.target)) {
        closeMenu()
      }
    }

    const keyListener = e => {
      if (e.key === "Escape") {
        closeMenu()
      }
    }

    button.addEventListener("click", e => {
      e.preventDefault()
      menu.classList.toggle("show")
      document.addEventListener("click", clickAwayListener)
      document.addEventListener("keyup", keyListener)
    })

    menu.querySelectorAll("a.dropdown-item").forEach(a => {
      a.addEventListener("click", () => closeMenu());
    })
  }
}
