import React, { useState } from 'react';
import Modal from 'react-modal';

const modalStyles = {
  content: {
    maxWidth: '900px',
    margin: 'auto',
    padding: 0,
  },
  overlay: {
    zIndex: '30',
    backgroundColor: 'rgba(20, 48, 72, 0.75)',
  }
}

const contentStyle = {
  padding: 20,
  border: 0,
}

export default function({ isOpen, title, onClose, children }) {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={title}
      style={modalStyles}
    >
      <div className="modal-header">
        <h2>{title}</h2>
        <button className="btn btn-outline-primary" onClick={() => onClose()}>
          <i className="fa fa-remove"/>
        </button>
      </div>

      <div className="modal-content" style={contentStyle}>
        { children }
      </div>
    </Modal>
  )
}
