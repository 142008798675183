import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "categoryInput",
  ]

  connect() {
    this.adjustFormForType(this.categoryInputTarget.value)
  }

  typeChanged(e) {
    this.adjustFormForType(e.target.value)
  }

  adjustFormForType(category) {
    Array.from(this.element.querySelectorAll(`*[data-category]`)).forEach(e => {
      e.classList.toggle("d-none", e.dataset.category !== category)
    })
  }
}
