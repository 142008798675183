import { Controller } from "stimulus"

export default class extends Controller {
  add(e) {
    e.preventDefault()

    const { name } = e.target.dataset
    const parent = e.target.parentElement
    const newItem = document.createElement("label")
    newItem.innerHTML = `
      ${e.target.dataset.htmlPrefix || ""}
      <input type="checkbox" checked data-action="change->add-checkbox#remove" />
      <input type="text" name="${name}" />
    `
    parent.parentElement.insertBefore(newItem, parent)
    newItem.querySelector("input[type='text']").focus()
  }

  remove(e) {
    e.target.parentElement.remove()
  }
}
