import ActionCable from "../channels/consumer"
import notify from "./notify"

function updateBadge(count) {
  const badges = document.querySelectorAll(".badge-notification")

  badges.forEach(b => {
    b.classList.toggle("no-notifications", count === 0)
    b.innerHTML = count // eslint-disable-line no-param-reassign
  })
}

const subscription = ActionCable.subscriptions.create({
  channel: "NotificationsChannel",
}, {
  connected: () => {
    console.log("connected") // eslint-disable-line no-console
  },
  received: data => {
    if (data.action === "new_notification") {
      notify(data.title, data.body)
      updateBadge(data.notifications_count)
    }
  },
  disconnected: () => {
    console.log("disconnected") // eslint-disable-line no-console
  },
})
