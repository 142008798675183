import React from "react"
import strftime from "strftime"

import ProjectPosition from "./ProjectPosition"

export default function({ startDate, endDate, projectDaysCount, availableDepartments, availablePeople, projectPositions, defaultMessages, onChange }) {
  const [tab, setTab] = React.useState("people")

  const project = {
    startDate: startDate,
    endDate: endDate,
  }

  const positionsById = {}
  availableDepartments.forEach(d => {
    d.positions.forEach(p => positionsById[p.id] = p)
  })

  const peopleByPositionId = {}
  availablePeople.forEach(p => {
    p.positions.forEach(position => {
      if (!peopleByPositionId[position.id]) {
        peopleByPositionId[position.id] = []
      }

      peopleByPositionId[position.id].push(p)
    })
  })

  function handleUpdatePosition(position, props) {
    const index = projectPositions.findIndex(r => r === position)
    const newPositions = [...projectPositions]

    newPositions[index] = { ...newPositions[index], ...props }
    onChange(newPositions)
  }

  function handleRemovePosition(position) {
    const index = projectPositions.findIndex(r => r === position)
    const newPositions = [...projectPositions]

    if (newPositions[index].id) {
      newPositions[index] = { ...newPositions[index], remove: true }
    } else {
      newPositions.splice(index, 1)
    }

    onChange(newPositions)
  }

  function handleRestorePosition(e, position) {
    e.preventDefault()
    handleUpdatePosition(position, { remove: false })
  }

  function handleAddPosition(e) {
    const assignmentStrategy = "first_come_first_serve_assignment"
    e.preventDefault()
    const newPositions = [...projectPositions]
    newPositions.push({
      // TODO: these defaults should be elsewhere
      name: "",
      positionId: "",
      daysCount: projectDaysCount,
      assignmentStrategy,
      roundRobinHourDelay: 48,
      memberships: [],
      lineItems: [],
      startHalfDay: false,
      endHalfDay: false,
      openingsCount: 1,
      ...defaultMessages,
    })
    onChange(newPositions)
  }

  return (
    <div className="bev-project-team-form">
      <div className="dc-dashboardboxtitle">
        <div className="pull-right">
          {strftime("%b %-d, %Y", startDate)} - {strftime("%b %-d, %Y", endDate)}

          <div className="btn-group ml-4" role="group">
            <button type="button" className={tab === "people" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => setTab("people")}>
              People
            </button>
            <button type="button" className={tab === "additional_pay" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => setTab("additional_pay")}>
              Additional Pay
            </button>
            <button type="button" className={tab === "messages" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => setTab("messages")}>
              Messages
            </button>
          </div>
        </div>
        <h2>Project Team</h2>
      </div>
      <div className="dc-dashboardboxcontent dc-addservices">
        {projectPositions.filter(p => !p.remove).map((projectPosition, i) => (
          <ProjectPosition
            key={i}
            tab={tab}
            project={project}
            projectPosition={projectPosition}
            position={positionsById[projectPosition.positionId] || {}}
            daysCount={projectPosition.daysCount}
            defaultMessages={defaultMessages}
            availableDepartments={availableDepartments}
            availablePeople={peopleByPositionId[projectPosition.positionId] || []}
            onChange={p => handleUpdatePosition(projectPosition, p)}
            onRemove={() => handleRemovePosition(projectPosition)}
          />
        ))}
        {projectPositions.filter(p => p.remove).map((projectPosition, i) => (
          <div key={i} className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p>
                {(positionsById[projectPosition.positionId] || {}).name}
                { projectPosition.roleName && ` (${projectPosition.roleName})`}
                &nbsp;position removed.&nbsp;
                <a href="#" onClick={e => handleRestorePosition(e, projectPosition)}>Restore</a>
              </p>
            </div>
          </div>
        ))}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
            <button className="btn btn-outline-primary btn-block" onClick={handleAddPosition}>
              Add Position
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
