import React from "react"

function ErrorMessage({ message }) {
  const [isOpen, setOpen] = React.useState(false)

  function toggle(e) {
    e.preventDefault()
    setOpen(!isOpen)
  }

  return (
    <p className="alert alert-danger mb-0">
      Something went wrong. <a href="#" onClick={toggle}>View Details</a>
      {isOpen && (
        <>
          <br/>
          <br/>
          {message}
        </>
      )}
    </p>
  )
}

// https://reactjs.org/docs/error-boundaries.html
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorString: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage message={this.state.errorString} />
    }

    return this.props.children;
  }
}
