import React from "react"
import ReactTooltip from "react-tooltip"

export default function({ value, ...rest }) {
  return (
    <>
      <span data-tip={value} {...rest}>
        <i className="fa fa-question-circle fa-tooltip" />
      </span>

      <ReactTooltip />
    </>
  )
}
