import React from "react"

import RateDurationSelect from "../shared/RateDurationSelect"
import Tooltip from "../shared/Tooltip"

// TODO put instructions for liquid variables somewhere

function MessageField({ id, label, value, tooltip, onChange }) {
  function handleChange(e) {
    onChange(e.target.value)
  }

  return (
    <div className="form-group col-md-12">
      {tooltip && <Tooltip value={tooltip} className="float-right" />}
      <label htmlFor={id}>{label}</label>
      <textarea
        className="form-control"
        id={id}
        rows="4"
        value={value}
        onChange={onChange && handleChange}
      />
    </div>
  )
}

export function InvitationMessageField(props) {
  return (
    <MessageField
      label="Invitation Message"
      tooltip="This message offers the job to the team member and should ask them to respond."
      {...props}
    />
  )
}

export function AcceptedMessageField(props) {
  return (
    <MessageField
      label="Accepted Message"
      tooltip="This message is delivered to the team member when they accept the job."
      {...props}
    />
  )
}

export function DeclinedMessageField(props) {
  return (
    <MessageField
      label="Declined Message"
      tooltip="This message is delivered to the team member when they decline the job."
      {...props}
    />
  )
}

export function AlreadyTakenMessageField(props) {
  return (
    <MessageField
      label="Position Already Filled Message"
      tooltip="This message is delivered to the team member when they accept the job but it's already been taken."
      {...props}
    />
  )
}

export function DelayBetweenMessagesField({ roundRobinHourDelay, onChange }) {
  return (
    <div className="offset-md-4 col-md-4 mt-4 mb-4">
      <Tooltip value="The number of hours between each team member receiving the invitation to accept the job offer, allowing you to prioritize who you'd like to offer the position to first." className="float-right" />
      <label>Delay between messages</label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={roundRobinHourDelay}
          onChange={e => onChange({ roundRobinHourDelay: e.target.value })}
        />
        <div className="input-group-append">
          <span className="input-group-text">hours</span>
        </div>
      </div>
    </div>
  )
}

export function UseDefaultMessageButton({ onClick }) {
  function handleClick(e) {
    e.preventDefault()
    onClick && onClick()
  }

  return (
    <div className="form-group col-md-12 text-right">
      <button className="btn btn-outline-danger" onClick={handleClick}>
        Use Default Messages
      </button>
    </div>
  );
}

export function LineItemsField({ lineItems, onChange }) {
  function handleChange(i, props) {
    const copy = [...lineItems]
    copy[i] = {
      ...lineItems[i],
      ...props,
    }

    onChange({ lineItems: copy })
  }

  function handleAdd(e) {
    e.preventDefault()
    const copy = [...lineItems]
    copy.push({
      name: "",
      rateCurrency: "USD",
      rateDuration: "per_project",
    })
    onChange({ lineItems: copy })
  }

  function handleRemove(e, i) {
    e.preventDefault()
    const copy = [...lineItems]

    if (copy[i].id) {
      copy[i] = { ...copy[i], remove: true }
    } else {
      copy.splice(i, 1)
    }

    onChange({ lineItems: copy })
  }

  // HACK: how to use lineItems.map(<> ... </>) without a key warning?
  const fields = []
  lineItems.filter(li => !li.remove).forEach((lineItem, i) => {
    fields.push((
      <div key={`${i}-name`} className="col-md-4">
        <input
          type="text"
          className="form-control"
          value={lineItem.name}
          onChange={e => handleChange(i, { name: e.target.value })}
        />
      </div>
    ))
    fields.push((
      <div key={`${i}-rate`} className="col-md-4">
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text" id="">$</span>
          </div>
          <input
            type="text"
            className="form-control"
            value={lineItem.rateAmount}
            onChange={e => handleChange(i, { rateAmount: e.target.value })}
          />
          <div className="input-group-append">
            <RateDurationSelect
              value={lineItem.rateDuration}
              onChange={rateDuration => handleChange(i, { rateDuration })}
            />
          </div>
        </div>
      </div>
    ))
    fields.push((
      <div key={`${i}-actions`} className="col-md-4">
        <button className="btn btn-outline-danger" onClick={e => handleRemove(e, i)}>
          <i className="fa fa-times" />
        </button>
      </div>
    ))
  })

  return (
    <>
      <div className="form-group col-md-4 mb-0">
        <Tooltip value="Line items for pay that isn't included in this role's base salary" className="float-right" />
        <label>Additional pay</label>
      </div>
      <div className="offset-md-8"></div>
      {fields}
      <div className="col-md-4">
        <button className="btn btn-outline-primary btn-block mb-4" onClick={handleAdd}>
          Add item
        </button>
      </div>
      <div className="offset-md-8"></div>
    </>
  )
}
