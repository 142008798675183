import { Controller } from "stimulus"
import ActionCable from "../channels/consumer"

import { htmlToElement, appendHTML } from "../services/dom"
import getParams from "../services/getParams"

export default class extends Controller {
  static targets = ["newMessageForm", "newMessageText", "newMessageButton"]

  connect() {
    this.subscription = ActionCable.subscriptions.create({
      channel: "MessagesChannel",
      ...getParams(this.element.dataset, {
        projectId: "project_id",
        personId: "person_id",
        groupId: "group_id",
      }),
    }, {
      connected: () => {
        console.log("connected") // eslint-disable-line no-console
        this.newMessageButtonTarget.removeAttribute("disabled")
      },
      received: data => {
        if (data.action === "new_message") {
          console.log("received new message") // eslint-disable-line no-console
          this.appendNewMessage(data)
        }
      },
      disconnected: () => {
        console.log("disconnected") // eslint-disable-line no-console
      },
    })

    // until we're subscribed
    this.newMessageButtonTarget.setAttribute("disabled", true)

    this.newMessageFormTarget.addEventListener("submit", e => {
      e.preventDefault()
      this.subscription.send({ message: this.newMessageTextTarget.value })
      this.newMessageTextTarget.value = ""
    })
  }

  disconnect() {
    if (this.subscription) {
      ActionCable.subscriptions.remove(this.subscription)
    }
  }

  toggleRecipients() {
    this.element.classList.toggle("bev-messages-chat-recipients")
  }

  appendNewMessage(data) {
    const latestMessage = this.getLatestMessage()
    const latestPhoto = latestMessage && latestMessage.querySelector("figure img")
    const newPhoto = htmlToElement(data.photo_tag)
    const sameUser = latestPhoto && latestPhoto.getAttribute("src") === newPhoto.getAttribute("src")

    if (sameUser) {
      // just need to append the message and update the timestamp
      latestMessage.querySelector("time").remove()

      const description = latestMessage.querySelector(".dc-description")
      appendHTML(description, data.log)
      appendHTML(description, data.time)
    } else {
      // need to append the message, photo, and timestamp
      const messages = this.element.querySelector(".dc-messages")
      appendHTML(messages, data.grouped_log)
    }

    this.getLatestMessage().querySelector("time").scrollIntoView()
  }

  getLatestMessage() {
    return this.element.querySelector(".dc-offerermessage:last-child,.dc-memessage:last-child")
  }
}
