import React from "react"

import parseDate from "../shared/parseDate"

import ErrorBoundary from "../shared/ErrorBoundary"
import ProjectPositions from "./ProjectPositions"

function ProjectPositionsField({ i, attribute, value }) {
  return (
    <input
      type="hidden"
      name={`project[project_positions_attributes][${i}][${attribute}]`}
      value={value}
    />
  )
}

function ProjectMembershipsField({ i, j, attribute, value }) {
  return (
    <input
      type="hidden"
      name={`project[project_positions_attributes][${i}][project_memberships_attributes][${j}][${attribute}]`}
      value={value}
    />
  )
}

function ProjectPositionLineItemField({ i, j, attribute, value }) {
  return (
    <input
      type="hidden"
      name={`project[project_positions_attributes][${i}][project_line_items_attributes][${j}][${attribute}]`}
      value={value}
    />
  )
}

function Container({ projectPositions, startDate, endDate, ...rest }) {
  const [state, setState] = React.useState(projectPositions)

  return (
    <>
      <ProjectPositions
        {...rest}
        startDate={parseDate(startDate)}
        endDate={parseDate(endDate)}
        projectPositions={state}
        onChange={setState}
      />

      {state.map((s, i) => (
        <div key={i}>
          {s.id && <ProjectPositionsField i={i} attribute="id" value={s.id || ""} />}
          <ProjectPositionsField i={i} attribute="_remove" value={s.remove || ""} />
          <ProjectPositionsField i={i} attribute="number" value={i} />
          <ProjectPositionsField i={i} attribute="role_name" value={s.roleName} />
          <ProjectPositionsField i={i} attribute="position_id" value={s.positionId} />
          <ProjectPositionsField i={i} attribute="assignment_strategy" value={s.assignmentStrategy} />
          <ProjectPositionsField i={i} attribute="openings_count" value={s.openingsCount} />
          <ProjectPositionsField i={i} attribute="round_robin_hour_delay" value={s.roundRobinHourDelay} />

          <ProjectPositionsField i={i} attribute="start_date" value={s.startDate} />
          <ProjectPositionsField i={i} attribute="end_date" value={s.endDate} />
          <ProjectPositionsField i={i} attribute="start_half_day" value={s.startHalfDay} />
          <ProjectPositionsField i={i} attribute="end_half_day" value={s.endHalfDay} />

          <ProjectPositionsField i={i} attribute="invitation_message" value={s.invitationMessage} />
          <ProjectPositionsField i={i} attribute="accepted_message" value={s.acceptedMessage} />
          <ProjectPositionsField i={i} attribute="declined_message" value={s.declinedMessage} />
          <ProjectPositionsField i={i} attribute="already_taken_message" value={s.alreadyTakenMessage} />

          {s.memberships.map((m, j) => (
            <div key={j}>
              <ProjectMembershipsField i={i} j={j} attribute="id" value={m.id || ""} />
              <ProjectMembershipsField i={i} j={j} attribute="number" value={j} />
              <ProjectMembershipsField i={i} j={j} attribute="person_id" value={m.person.id} />

              <ProjectMembershipsField i={i} j={j} attribute="start_date" value={m.startDate || ""} />
              <ProjectMembershipsField i={i} j={j} attribute="end_date" value={m.endDate || ""} />
              <ProjectMembershipsField i={i} j={j} attribute="start_half_day" value={m.startHalfDay || ""} />
              <ProjectMembershipsField i={i} j={j} attribute="end_half_day" value={m.endHalfDay || ""} />

              <ProjectMembershipsField i={i} j={j} attribute="rate_amount" value={m.rateAmount || ""} />
              <ProjectMembershipsField i={i} j={j} attribute="rate_currency" value={m.rateCurrency || "USD"} />
              <ProjectMembershipsField i={i} j={j} attribute="rate_duration" value={m.rateDuration || "per_day"} />

              <ProjectMembershipsField i={i} j={j} attribute="_remove" value={m.remove || ""} />
            </div>
          ))}

          {(s.lineItems || []).map((li, j) => (
            <div key={j}>
              <ProjectPositionLineItemField i={i} j={j} attribute="id" value={li.id || ""} />
              <ProjectPositionLineItemField i={i} j={j} attribute="number" value={j} />
              <ProjectPositionLineItemField i={i} j={j} attribute="name" value={li.name || ""} />
              <ProjectPositionLineItemField i={i} j={j} attribute="rate_amount" value={li.rateAmount || ""} />
              <ProjectPositionLineItemField i={i} j={j} attribute="rate_currency" value={li.rateCurrency} />
              <ProjectPositionLineItemField i={i} j={j} attribute="rate_duration" value={li.rateDuration} />
              <ProjectPositionLineItemField i={i} j={j} attribute="_destroy" value={li.remove || ""} />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default function(props) {
  return (
    <ErrorBoundary>
      <Container {...props} />
    </ErrorBoundary>
  )
}
