import React from "react"
import strftime from "strftime"
import { DateRange } from "react-date-range"
import Modal from "./Modal"
import parseDate from "./parseDate"

function renderButtonLabel({ startDate, endDate, startHalfDay, endHalfDay }) {
  const text = []

  if (!startDate || !endDate) {
    return "Inherit dates"
  }

  text.push(strftime("%b %-d", startDate))
  if (startHalfDay) {
    text.push("(midday)")
  }

  if (startDate.toString() !== endDate.toString()) {
    text.push("-")
    text.push(strftime("%b %-d", endDate))
    if (endHalfDay) {
      text.push("(midday)")
    }
  }

  return text.join(" ")
}

export default function({ startDate, endDate, startHalfDay, endHalfDay, minDate, maxDate, className, onChange }) {
  minDate = parseDate(minDate)
  maxDate = parseDate(maxDate)
  startDate = parseDate(startDate)
  endDate = parseDate(endDate)

  const [state, setState] = React.useState({
    isOpen: false,
    startDate: startDate,
    endDate: endDate,
    startHalfDay: startHalfDay,
    endHalfDay: endHalfDay,
  })

  function handleChange(e) {
    setState({
      ...state,
      startDate: e.selection.startDate,
      endDate: e.selection.endDate,
    })
  }

  function handleOpen(e) {
    e.preventDefault()
    setState({ ...state, isOpen: true })
  }

  function handleSelect(e) {
    e.preventDefault()
    setState({ ...state, isOpen: false })
    onChange({
      startDate: strftime("%Y-%m-%d", state.startDate || minDate),
      endDate: strftime("%Y-%m-%d", state.endDate || maxDate),
      startHalfDay: state.startHalfDay,
      endHalfDay: state.endHalfDay,
    })
  }

  const css = ["btn btn-outline-secondary btn-input btn-ellipsis"]
  if (className) {
    css.push(className)
  }

  return (
    <>
      <button className={css.join(" ")} onClick={handleOpen}>
        {renderButtonLabel({
          startDate,
          endDate,
          startHalfDay,
          endHalfDay,
        })}
      </button>

      <Modal
        isOpen={state.isOpen}
        title={"Select Days"}
        onClose={() => setState({ ...state, isOpen: false })}
      >
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="date-range-modal">
              <DateRange
                months={1}
                direction="vertical"
                scroll={{ enabled: true }}
                ranges={[{
                  startDate: state.startDate || minDate,
                  endDate: state.endDate || maxDate,
                  key: "selection",
                }]}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="form-check">
              <label className="form-check-label">
                <input className="form-check-input"
                  type="checkbox"
                  checked={state.startHalfDay || ""}
                  onChange={e => setState({ ...state, startHalfDay: e.target.checked })}
                />
                Starts midday the first day
              </label>
            </div>
            <div className="form-check mt-4">
              <label className="form-check-label">
                <input className="form-check-input"
                  type="checkbox"
                  checked={state.endHalfDay || ""}
                  onChange={e => setState({ ...state, endHalfDay: e.target.checked })}
                />
                Ends midday the last day
              </label>
            </div>
            <button className="btn btn-block btn-primary mt-4" onClick={handleSelect}>
              Select Dates
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
