import React from "react"
import { SortableHandle } from 'react-sortable-hoc'

import RateDurationSelect from "../shared/RateDurationSelect"
import DateRangePicker from "../shared/DateRangePicker"

import I18n from '../../services/I18n.js.erb'

function getMembershipStatus(status) {
  return status && I18n.t(`project_memberships.statuses.${status}`)
}

const Handle = SortableHandle(() => <i className="handle fa fa-arrows"/>)

export default function({ project, membership, position, onChange, onRemove }) {
  const { status, person } = membership

  function handleChange(props) {
    onChange({ ...membership, ...props })
  }

  function handleRemove(e) {
    e.preventDefault()
    onRemove()
  }

  const css = ["bev-project-position-membership-form"]
  if (membership.remove) {
    css.push("d-none")
  }

  return (
    <tr data-id={membership.id} className={css.join(" ")}>
      <td className="bev-person-handle">
        <Handle />
      </td>
      <td className="bev-person-name">
        <a href={`/people/${person.id}`} target="_blank">{person.fullName}</a>
      </td>
      <td>
        {getMembershipStatus(!membership.remove && membership.status)}
      </td>
      <td className="bev-person-rate">
        <div className="input-group input-group-sm mt-2 mb-2">
          <div className="input-group-prepend">
            {/** TODO: support other currencies? */}
            <span className="input-group-text" id="">$</span>
          </div>
          <input
            type="text"
            className="form-control"
            value={membership.rateAmount || ""}
            onChange={e => handleChange({ rateAmount: e.target.value })}
          />
          <div className="input-group-append">
            <RateDurationSelect
              value={membership.rateDuration || "per_day"}
              onChange={rateDuration => handleChange({ rateDuration })}
            />
          </div>
        </div>
      </td>
      <td>
        <DateRangePicker
          minDate={project.startDate}
          maxDate={project.endDate}
          startDate={membership.startDate}
          endDate={membership.endDate}
          startHalfDay={membership.startHalfDay}
          endHalfDay={membership.endHalfDay}
          onChange={handleChange}
          className="btn-sm mt-2"
        />
      </td>
      <td className="text-right">
        <a href="#" className="btn btn-outline-danger btn-xs" onClick={handleRemove}>
          <i className="fa fa-times"/>
        </a>
      </td>
    </tr>
  )
}
