export default function getParams(dataset, attributes) {
  const params = {}

  Object.keys(attributes).forEach(key => {
    const param = attributes[key]

    if (dataset[key]) {
      params[param] = dataset[key]
    }
  })

  return params
}
