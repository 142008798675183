import { Controller } from "stimulus"

export default class PicturePreviewController extends Controller {
  static targets = ["preview"]

  update(e) {
    // https://csspoint101.com/file-upload-and-image-preview-in-javascript/
    const files = e.target.files[0]
    const preview = this.previewTarget
    if (files) {
      const fileReader = new FileReader()
      fileReader.addEventListener("load", event => {
        preview.setAttribute("src", event.target.result)
      })
      fileReader.readAsDataURL(files)
    }
  }
}
