import React from "react"
import Modal from "../shared/Modal";

function renderRate(person, positionId) {
  const position = person.positions.find(p => p.id === positionId) || {}
  const { rateAmount, rateCurrency, rateDuration } = position

  if (rateAmount && rateCurrency && rateDuration) {
    return `$${rateAmount} ${rateDuration.replace("_", " ")}`
  }
}

export default function({ isOpen, positionId, positionName, roleName, availablePeople, onAdd, onCancel }) {
  const [selected, setSelected] = React.useState([])

  function handleSelect(e, person) {
    e.preventDefault()

    if (selected.indexOf(person.id) === -1) {
      setSelected([...selected, person.id])
    } else {
      setSelected(selected.filter(s => s !== person.id))
    }
  }

  function handleAdd(e) {
    const people = selected.map(id => availablePeople.find(p => p.id === id))
    e.preventDefault()
    onAdd(people)
    setSelected([])
  }

  // TODO: way to add people not marked for a specific position
  // TODO: way to create a new person and add them all within this modal
  return (
    <Modal
      isOpen={isOpen}
      title={`Add ${positionName} ${roleName}`}
      onClose={onCancel}
    >
      <div className="add-position-memberships-modal">
        <div className="row">
          <table className="table table-border-row table-hover position-memberships">
            <thead>
              <tr>
                <td>Person</td>
                <td>Email</td>
                <td>Phone</td>
                <td>Default Rate</td>
                <td className="text-right">Actions</td>
              </tr>
            </thead>
            <tbody>
              {availablePeople.map(person => (
                <tr key={person.id}>
                  <td>
                    <a href={`/people/${person.id}`} target="_blank">{person.fullName}</a>
                  </td>
                  <td>{person.email}</td>
                  <td>{person.phoneNumber}</td>
                  <td>{renderRate(person, positionId)}</td>
                  <td className="text-right">
                    {selected.indexOf(person.id) === -1 ? (
                      <a href="#" className="btn btn-sm" onClick={e => handleSelect(e, person)}>
                        Select
                      </a>
                    ) : (
                      <a href="#" className="btn btn-sm btn-primary" onClick={e => handleSelect(e, person)}>
                        De-Select
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-md-12">
            {availablePeople.length === 0 ? (
              <p className="alert alert-info text-center">There are no more people available for this position.</p>
            ) : (
              <button disabled={selected.length === 0} className="btn btn-block btn-primary" onClick={handleAdd}>
                Add to Position
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
