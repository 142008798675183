import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["details"]

  toggleDetails(e) {
    e.preventDefault()
    this.element.classList.toggle("expanded")
  }
}
