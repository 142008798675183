import React from "react"
import ReactDOM from "react-dom"
import ReactModal from "react-modal"
import { Controller } from "stimulus"

// TODO: more abstract way of importing/identifying react components to embed
import ProjectTeamForm from "../components/project-team-form/Container"

function readProperties(string) {
  if (typeof string === "string") {
    return JSON.parse(string)
  }

  return null
}

export default class extends Controller {
  connect() {
    const { component } = this.element.dataset
    const properties = readProperties(this.element.dataset.properties)

    ReactModal.setAppElement("body");

    if (component === "ProjectTeamForm") {
      // eslint-disable-next-line react/jsx-props-no-spreading
      ReactDOM.render(<ProjectTeamForm {...properties} />, this.element)
    }
  }
}
