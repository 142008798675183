// TODO: use a library that doesn't require jQuery
import toastr from "toastr"
import Turbolinks from "turbolinks"

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  showDuration: "10",
  hideDuration: "1000",
  timeOut: "0",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  onclick: () => Turbolinks.visit("/profile/notifications"),
}

export default function notify(title, body) {
  toastr.info(body, title)
}
