export function htmlToElement(html) {
  const temp = document.createElement("template")
  temp.innerHTML = html.trim() // Never return a space text node as a result
  return temp.content.firstChild
}

export function appendHTML(element, html) {
  const temp = document.createElement("div")
  temp.innerHTML = html

  Array.from(temp.children).forEach(child => {
    element.appendChild(child)
  })
}
