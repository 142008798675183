import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const { location } = this.element.dataset
    if (location === "bottom") {
      this.element.scrollTop = this.element.scrollHeight - this.element.clientHeight
    } else if (location === "selector") {
      const { selector } = this.element.dataset
      const elem = selector && this.element.querySelector(selector)
      if (elem) {
        elem.scrollIntoView()
      }
    }
  }
}
